import React, { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";

import Loading from "./components/Loading";

import useWindowDimensions from "./utils/screenSize";

// import PublicRoutes from "./routes/routes";
import "./App.scss";
import { FaInstagram } from "react-icons/fa";

const Mobile = lazy(() => import("./routes/mobile"));
const Web = lazy(() => import("./routes/web"));

function App() {
  const { isMobile } = useWindowDimensions();

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading className="loader" />}>
        {/* <div style={{ minHeight: "100vh", height: "auto" }}> */}
        {isMobile ? <Mobile /> : <Web />}
        {/* </div> */}
        <footer className="myFooter">
          ©2024 SerbiaRun® all rights reserved.
          <span>
            Follow us on:
            <a href="https://www.instagram.com/serbiarun/" target="_blank">
              <FaInstagram color="white" size={"1rem"} />
            </a>
          </span>
        </footer>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
